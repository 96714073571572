// ---------------------------------------------
// ======---------------------------------======
//  Site JS
// ======---------------------------------======
// ---------------------------------------------

//===-----------------===
// Reusable functions
//===-----------------===
const classToggle = function (elements, elementClass) {
	elements.forEach((element) => {
		if (!element.classList.contains(`${elementClass}`)) {
			element.classList.add(`${elementClass}`);
		} else {
			element.classList.remove(`${elementClass}`);
		}
	});
};

const alertStatus = function (alert, alertMessage) {
	document.querySelector(alert).innerHTML = `<p>${alertMessage}</p>`;
};

//===-----------------===
// Mobile menu
//===-----------------===
const navigation = document.querySelector('.site-nav__mobile nav');
// Checking for the navigation
if (navigation) {
	navigation.addEventListener('click', function () {
		const menu = navigation.querySelector('ul');
		const body = document.querySelector('body');
		const overlay = document.querySelector('.overlay');
		// Toggling the classes for the menu, overlay, and body
		classToggle([body, menu, overlay], 'js-menu-open');
		// Setting an alert status for the menu, overlay, and body
		if (menu.classList.contains('js-menu-open')) {
			alertStatus('#nav-status', 'Menu opened');
		} else {
			alertStatus('#nav-status', 'Menu closed');
		}
		// Checking to see if the menu is openened or closed to change the buttons text and aria-expanded values
		if (menu.classList.contains('js-menu-open')) {
			navigation.querySelector('#site-nav__txt').innerHTML = 'Close';
			navigation.querySelector('.site-nav__btn').setAttribute('aria-expanded', true);
		} else {
			navigation.querySelector('#site-nav__txt').innerHTML = 'Menu';
			navigation.querySelector('.site-nav__btn').setAttribute('aria-expanded', false);
		}
	});
}

//===-----------------===
// Video player
//===-----------------===
const video = document.querySelector('#skai-video');

if (video) {
	const watchBtn = video.parentNode.querySelector('.video-controls');
	const options = {};
	const player = new Vimeo.Player(video, options);

	watchBtn.addEventListener('click', function (e) {
		e.stopImmediatePropagation();
		if (!watchBtn.classList.contains('js-video-playing')) {
			player.play();
			classToggle([watchBtn], 'js-video-playing');
			watchBtn.innerHTML = 'Pause';
		} else {
			player.pause();
			watchBtn.classList.remove('js-video-playing');
			watchBtn.innerHTML = 'Watch';
		}
	});

	video.parentNode.addEventListener('click', function (e) {
		e.stopImmediatePropagation();
		if (!watchBtn.classList.contains('js-video-playing')) {
			player.play();
			classToggle([watchBtn], 'js-video-playing');
			watchBtn.innerHTML = 'Pause';
		} else {
			player.pause();
			watchBtn.classList.remove('js-video-playing');
			watchBtn.innerHTML = 'Watch';
		}
	});
}

//===-----------------===
// Carousel
//===-----------------===
const carousels = document.querySelectorAll('.carousel-wrapper');

if (carousels) {
	carousels.forEach((carouselElement) => {
		const nextControl = carouselElement.querySelector('.control-next');
		const prevControl = carouselElement.querySelector('.control-prev');
		const carouselNumber = carouselElement.querySelector('.carousel-number');
		let position = 1;
		const opacityIncrement = 0.2;
		const opacityItem = document.querySelector('.color-change');

		function updateCarouselStatus() {
			carouselNumber.innerHTML = `${position}/${items.length}`;
			alertStatus('#carousel-status', `Viewing carousel item ${position} of ${items.length}`);
		}

		function updateControls() {
			prevControl.disabled = position === 1;
			nextControl.disabled = position === items.length;
		}

		function increaseOpacity() {
			opacityItem.style.opacity = parseFloat(opacityItem.style.opacity) + opacityIncrement;
		}

		function decreaseOpacity() {
			opacityItem.style.opacity = parseFloat(opacityItem.style.opacity) - opacityIncrement;
		}

		function goToNext() {
			position++;

			increaseOpacity();
			updateCarouselStatus();
			updateControls();

			const activeItem = carouselElement.querySelector('.carousel-item.active');
			const nextItem = activeItem.nextElementSibling;
			if (nextItem && nextItem.classList.contains('carousel-item')) {
				activeItem.classList.remove('active');
				nextItem.classList.add('active');
			}
		}

		function goToPrevious() {
			position--;
			decreaseOpacity();
			updateCarouselStatus();
			updateControls();

			const activeItem = carouselElement.querySelector('.carousel-item.active');
			const prevItem = activeItem.previousElementSibling;
			if (prevItem && prevItem.classList.contains('carousel-item')) {
				activeItem.classList.remove('active');
				prevItem.classList.add('active');
			}
		}

		nextControl.addEventListener('click', goToNext);
		prevControl.addEventListener('click', goToPrevious);

		const items = carouselElement.querySelectorAll('.carousel-item');

		updateCarouselStatus();
		updateControls();
	});
}

//===-----------------===
// Accordions
//===-----------------===
const accordions = document.querySelectorAll('.accordion');

if (accordions) {
	accordions.forEach((accordion) => {
		const accordionHeader = accordion.querySelector('.accordion-header');
		accordionHeader.addEventListener('click', function () {
			const accordionText = accordionHeader.innerText;
			const isActive = accordion.classList.contains('active');
			accordions.forEach((element) => {
				element.classList.remove('active');
				element.setAttribute('aria-expanded', false);
				alertStatus('#accordion-status', `${accordionText} accordion is closed`);
			});
			if (!isActive) {
				accordion.classList.add('active');
				accordion.setAttribute('aria-expanded', true);
				alertStatus('#accordion-status', `${accordionText} accordion is open`);
			}
		});
	});
}

//===-----------------===
// Sticky desktop nav
//===-----------------===
if (typeof document.querySelector('.site-header') !== 'undefined') {
	let lastScrollTopNav = 0;
	const body = document.querySelector('body');
	const siteHeader = document.querySelector('.site-header');

	function setHeaderHeight() {
		const headerHeight = siteHeader.getBoundingClientRect().height;
		body.style.setProperty('--js-header-height', `${headerHeight}px`);
	}
	setHeaderHeight();

	setTimeout(() => {
		window.addEventListener(
			'scroll',
			function () {
				const st = window.scrollY || document.documentElement.scrollTop;
				const headerHeight = siteHeader.getBoundingClientRect().height;
				setHeaderHeight();

				if (lastScrollTopNav <= headerHeight || st > lastScrollTopNav) {
					if (
						body.getAttribute('data-sticky') === 'header' &&
						body.getAttribute('data-menu-status') != 'open'
					) {
						body.setAttribute('data-sticky', 'header-out');

						setTimeout(() => {
							body.removeAttribute('data-sticky');
							body.classList.remove('sticky-header');
						}, 300);
					}
				} else {
					if (body.getAttribute('data-sticky') !== 'header') {
						body.classList.add('sticky-header');
						body.setAttribute('data-sticky', 'header-in');

						setTimeout(() => {
							if (lastScrollTopNav <= headerHeight) {
								body.removeAttribute('data-sticky');
								body.classList.add('sticky-header');
							} else {
								body.setAttribute('data-sticky', 'header');
							}
						}, 300);
					}
				}
				lastScrollTopNav = st <= 0 ? 0 : st;
			},
			false
		);
	}, 50);
}

// ====---------------====
// Social Share
// ====---------------====
const sharingOptions = document.querySelector('.social-share');
if (sharingOptions) {
	const sharingOptionsArry = document.querySelectorAll('.social-share');
	const sharePageUrl = window.location.href;
	let soLinkedin = `https://www.linkedin.com/shareArticle?mini=true&url=${sharePageUrl}`;
	let soFacebook = `https://www.facebook.com/sharer/sharer.php?u=#${sharePageUrl}`;
	let soTwitter = `http://www.twitter.com/share?url=${sharePageUrl}`;
	let soPage = `${sharePageUrl}`;

	function setLinks(pageLink, val) {
		pageLink.setAttribute('href', val);
	}

	function copiedSuccess(parentEl, linkEl) {
		const msg = 'link copied';
		const linkOffset = linkEl.getBoundingClientRect().top - parentEl.getBoundingClientRect().top;

		let msgEl = document.createElement('div');
		msgEl.innerHTML = msg;
		msgEl.classList.add('share-options__msg', 'share-options__msg--success');
		msgEl.style.setProperty('--msg-offset', `${linkOffset}px`);
		parentEl.appendChild(msgEl);

		setTimeout(() => {
			msgEl.remove();
		}, 800);
	}

	function updateClipboard(newClip, cb) {
		navigator.clipboard.writeText(newClip).then(
			() => {
				/* clipboard successfully set */
				console.log('success');
				cb;
			},
			() => {
				/* clipboard write failed */
				console.log('failed');
			}
		);
	}

	sharingOptionsArry.forEach((sharingOption) => {
		const soPageLinks = sharingOption.querySelectorAll('a[data-link="page-url"]');
		soPageLinks.forEach((pageLink) => {
			setLinks(pageLink, soPage);
			pageLink.addEventListener('click', (e) => {
				e.preventDefault();

				navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
					if (result.state === 'granted' || result.state === 'prompt') {
						updateClipboard(soPage, copiedSuccess(sharingOption, pageLink));
					}
				});
			});
		});

		const soLinkedinLinks = sharingOption.querySelectorAll('[data-link="linkedin"]');
		soLinkedinLinks.forEach((pageLink) => setLinks(pageLink, soLinkedin));

		const soFacebookLinks = sharingOption.querySelectorAll('[data-link="facebook"]');
		soFacebookLinks.forEach((pageLink) => setLinks(pageLink, soFacebook));

		const soTwitterLinks = sharingOption.querySelectorAll('[data-link="twitter"]');
		soTwitterLinks.forEach((pageLink) => setLinks(pageLink, soTwitter));
	});
}
