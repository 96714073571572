// ---------------------------------------------
// ======---------------------------------======
//  Animations JS
// ======---------------------------------======
// ---------------------------------------------

document.addEventListener('DOMContentLoaded', (event) => {
	gsap.registerPlugin(ScrollTrigger);

	const animationVariables = {
		opacityStart: 0,
		opacityEnd: 1,
		duration: 2,
		translationStart: 0,
		negativeTranslation: '-10rem',
		positiveTranslation: '10rem'
	};

	function fromLeft(elements) {
		if (document.querySelectorAll(elements)) {
			elements.forEach((element) => {
				const scrollTriggerElement = element.split(' ')[0];
				gsap.fromTo(
					element,
					{
						opacity: animationVariables.opacityStart,
						x: animationVariables.negativeTranslation
					},
					{
						scrollTrigger: scrollTriggerElement,
						opacity: animationVariables.opacityEnd,
						x: animationVariables.translationStart,
						duration: animationVariables.duration
					}
				);
			});
		}
	}

	function fromRight(elements) {
		if (document.querySelectorAll(elements)) {
			elements.forEach((element) => {
				const scrollTriggerElement = element.split(' ')[0];
				gsap.fromTo(
					element,
					{
						opacity: animationVariables.opacityStart,
						x: animationVariables.positiveTranslation
					},
					{
						scrollTrigger: scrollTriggerElement,
						opacity: animationVariables.opacityEnd,
						x: animationVariables.translationStart,
						duration: animationVariables.duration
					}
				);
			});
		}
	}

	function fromBottom(elements) {
		if (document.querySelectorAll(elements)) {
			elements.forEach((element) => {
				const scrollTriggerElement = element.split(' ')[0];
				gsap.fromTo(
					element,
					{
						y: animationVariables.positiveTranslation,
						opacity: animationVariables.opacityStart
					},
					{
						y: animationVariables.translationStart,
						opacity: animationVariables.opacityEnd,
						duration: animationVariables.duration,
						scrollTrigger: scrollTriggerElement
					}
				);
			});
		}
	}

	function fromTop(elements) {
		if (document.querySelectorAll(elements)) {
			elements.forEach((element) => {
				const scrollTriggerElement = element.split(' ')[0];
				gsap.fromTo(
					element,
					{
						opacity: animationVariables.opacityStart,
						y: animationVariables.negativeTranslation
					},
					{
						scrollTrigger: scrollTriggerElement,
						opacity: animationVariables.opacityEnd,
						y: animationVariables.translationStart,
						duration: animationVariables.duration
					}
				);
			});
		}
	}

	function fadeIn(elements) {
		if (document.querySelectorAll(elements)) {
			elements.forEach((element) => {
				const scrollTriggerElement = element.split(' ')[0];
				gsap.fromTo(
					element,
					{
						opacity: animationVariables.opacityStart
					},
					{
						opacity: animationVariables.opacityEnd,
						duration: animationVariables.duration,
						scrollTrigger: scrollTriggerElement
					}
				);
			});
		}
	}

	function scaleUp(elements) {
		if (document.querySelectorAll(elements)) {
			elements.forEach((element) => {
				const scrollTriggerElement = element.split(' ')[0];
				gsap.fromTo(
					element,
					{
						scale: 1
					},
					{
						scale: 1.5,
						transformOrigin: 'top left',
						duration: animationVariables.duration,
						scrollTrigger: scrollTriggerElement
					}
				);
			});
		}
	}

	// I am thinking that we can end up using these kind of formulas as an embed element in the actual page for future pages?
	fromBottom([
		'.js-banner-landing .inner > *:not(img)',
		'.js-banner-about .inner > *:not(img)',
		'#site-footer .inner',
		'.js-yes p',
		'.js-carousel h3',
		'.js-contact',
		'.js-img-grid img:nth-of-type(1)'
	]);
	fromLeft([
		'.js-yes h2',
		'.js-contact .col-left',
		'.js-carousel .carousel-content',
		'.js-accordions .col-left',
		'#articles'
	]);
	fromRight([
		'.js-yes h3',
		'.js-contact .col-right',
		'.js-carousel  .carousel-img',
		'.js-accordions .col-right',
		'.js-img-grid img:nth-of-type(2)'
	]);
	fadeIn(['.js-banner-about img', '.js-banner-insights']);
	fromTop(['.js-yes .more-arrow', '.js-img-grid .content']);
	scaleUp(['.js-banner-landing img']);
});
